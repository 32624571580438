.config {
  --color: #0080a4;
  --color-border: #ced4da;
  --margin: 16px;
  width: 100%;
  max-width: 720px;
}

.config > header {
  text-align: center;
  font-size: large;
  font-weight: bold;
  color: white;
  background-color: var(--color);
  padding: 16px;
  border-radius: 12px 12px 0 0;
}

.config > form {
  border: 8px solid var(--color);
  border-top: none;
  border-radius: 0 0 12px 12px;
  padding: var(--margin);
  background-color: #f8f8f8;
}

.name > input {
  width: 100%;
  background-color: white;
  border: 5px solid var(--color-border);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
  height: 64px;
}

.forceNegative {
  text-align: center;
  margin-top: var(--margin);
}

.forceNegative input {
  background-color: white;
  width: 32px;
  height: 32px;
  border: 5px solid var(--color-border);
  margin-left: 16px;
  vertical-align: middle;
}

.forceNegative input:checked {
  background-image: url("./checkmark.svg");
}

.forceNegative label {
  font-size: 20px;
  vertical-align: middle;
}

.generateButton {
  margin-top: calc(var(--margin) * 2);
}

.generateButton > input {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  background-color: var(--color);
  width: 100%;
  padding: var(--margin);
  cursor: pointer;
}

.generateButton > input:hover {
  opacity: 0.8;
}
