.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.jumbotron {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  width: 100%;
  min-height: 320px;
}

.jumbotron > h1 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block: 128px;
}

.footer {
  width: 100%;
  padding: 32px;
  margin-top: auto;
  background-color: rgb(44, 44, 44);
}

.footer > div {
  color: white;
  text-align: center;
}

.footer a {
  color: rgb(201, 201, 201);
}
